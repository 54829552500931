import clsx from 'clsx';
import { ImageProps } from 'next/image';
import Link, { LinkProps } from 'next/link';
import React from 'react';

import { ImageWithPlaceholder } from '../ImageWithPlaceholder/ImageWithPlaceholder';

export type TAwardSingleProps = {
  title?: string;
  description?: string;
  logo: ImageProps | null;
  listTitle?: string;
  link?: LinkProps | null;
  isActive?: boolean;
  id?: string;
};

export const AwardSingle: React.FC<TAwardSingleProps> = ({
  title,
  description,
  logo,
  link,
  listTitle,
  isActive,
}) => {
  const slideClass = clsx({
    'opacity-100': isActive,
    'opacity-30': !isActive,
  });
  const slideContentClasses = clsx({
    'opacity-100': isActive,
    'opacity-0': !isActive,
  });

  const awardIconClasses =
    'duration-800 h-12 w-[3rem] object-contain object-center transition-opacity';

  return (
    <>
      {listTitle && (
        <h3 className='mb-4 h-4 text-sm font-semibold leading-normal text-control-950'>
          {listTitle}
        </h3>
      )}
      {logo && (
        <div
          className={clsx(
            slideClass,
            'duration-800 transition-all lg:hover:opacity-100',
            { 'mt-8': !listTitle },
          )}
        >
          {link ? (
            <Link {...link}>
              <ImageWithPlaceholder
                key={title}
                {...logo}
                className={awardIconClasses}
              />
            </Link>
          ) : (
            <ImageWithPlaceholder
              key={title}
              {...logo}
              className={awardIconClasses}
            />
          )}
          <div
            className={clsx(
              'absolute inset-x-0 top-auto flex flex-col gap-2 pt-6 transition-opacity duration-300 lg:group-hover:opacity-100',
              slideContentClasses,
            )}
          >
            {title && (
              <h3 className='min-w-[10.875rem] text-sm font-semibold leading-normal text-control-950'>
                {title}
              </h3>
            )}
            {description && (
              <p className='min-w-[11.688rem] text-sm leading-extra-loose leading-loose text-control-600'>
                {description}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};
